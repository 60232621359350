<template>
  <div>
    <b-card class="p-0 mb-1" no-body>
      <b-nav class="navbar d-flex justify-content-between bd-highlight flex-wrap">
        <div class="d-flex">
          <b-form-radio @change="statusChang(statusValue)" v-model="statusValue" value="open" class="custom-control-danger"> เเจ้ง </b-form-radio>
          <b-form-radio @change="statusChang(statusValue)" v-model="statusValue" value="in progress" class="custom-control-info"> ดำเนินการ </b-form-radio>
          <b-form-radio @change="statusChang(statusValue)" v-model="statusValue" value="resolved" class="custom-control-success"> เสร็จเเล้ว </b-form-radio>
        </div>
        <div class="bd-highlight">
          <b-button
            variant="success"
            @click="
              () => {
                this.$router.push({ name: 'fromticket' });
                this.$store.dispatch('addChang', this.typeName);
              }
            "
          >
            เพิ่ม
          </b-button>
        </div>
      </b-nav>
    </b-card>
    <b-alert :show="isNodata" class="w-50 w-100" variant="danger">
      <!-- <h4 class="alert-heading">Danger</h4> -->
      <div class="alert-body text-center">
        <feather-icon icon="InfoIcon" class="mr-50" />
        <span>ไม่มีข้อมูล</span>
      </div>
    </b-alert>
    <div class="row">
      <div v-for="(item, index) in items" :key="index" class="col-12 col-md-6 col-lg-4">
        <b-overlay :show="playLoad" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
          <div class="card">
            <div class="item-img" v-if="item.urlImage.length">
              <swiper :options="swiperOptions" class="swiper-lazy-loading">
                <swiper-slide v-for="(image, index) in item.urlImage" :key="index">
                  <b-img :src="image" fluid center class="swiper-lazy card-img-top mw-100" style="max-width: 100%; height: 300px; object-fit: cover" />
                  <div v-if="index != 0">
                    <div class="swiper-lazy-preloader swiper-lazy-preloader-white" />
                  </div>
                </swiper-slide>
                <div slot="pagination" class="swiper-pagination" />
                <div slot="button-next" class="swiper-button-next swiper-button-white" />
                <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
              </swiper>
            </div>
            <div v-else>
              <div class="item-img" style="max-width: 100%; height: 300px; object-fit: cover">
                <b-overlay :show="true" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-wrap />
                <!-- <b-img src="https://zosepcar.cl/images/cargando.gif" class="card-img-top" style="width: 100%; height: 100%" /> -->
              </div>
            </div>
            <!-- <div v-else>
              <b-img src="https://th.bing.com/th/id/OIP.MvgjeMBHxcKil9_H4jQcvgAAAA?rs=1&pid=ImgDetMain" class="card-img-top" style="max-width: 100%; height: 300px; object-fit: cover" />
            </div> -->

            <!-- <div class="item-img" v-if="item.urlImage.length !== 0">
              <swiper :options="swiperOptions" class="swiper-lazy-loading">
                <swiper-slide v-for="(image, index) in item.urlImage" :key="index">
                  <b-img :src="image" fluid center class="swiper-lazy card-img-top mw-100" style="max-width: 100%; height: 300px; object-fit: cover" />
                  <div v-if="index != 0">
                    <div class="swiper-lazy-preloader swiper-lazy-preloader-white" />
                  </div>
                </swiper-slide>
                <div slot="pagination" class="swiper-pagination" />
                <div slot="button-next" class="swiper-button-next swiper-button-white" />
                <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
              </swiper>
            </div>
            <div v-else-if="item.urlImage.length == 0">
              <b-img src="https://th.bing.com/th/id/OIP.MvgjeMBHxcKil9_H4jQcvgAAAA?rs=1&pid=ImgDetMain" class="card-img-top" style="max-width: 100%; height: 300px; object-fit: cover" />
            </div> -->

            <div class="card-body">
              <span class="card-text">
                <span v-if="item.status === 'open'">
                  <b-badge variant="danger">
                    <span class="h5 text-white">เเจ้ง</span>
                  </b-badge>
                </span>
                <span v-else-if="item.status === 'in progress'">
                  <b-badge variant="info">
                    <span class="h5 text-white">ดำเนินการ</span>
                  </b-badge>
                </span>
                <span v-else-if="item.status === 'resolved'">
                  <b-badge variant="success">
                    <span class="h5 text-white">เสร็จเเล้ว</span>
                  </b-badge>
                </span>
                {{ item.title }}
              </span>
              <!-- <div class="row">
                <div v-for="(tags, index) in item.tag" :key="index" class="card-text">
                  <b-badge class="col-auto" variant="primary">
                    <feather-icon icon="HashIcon" class="mr-25" />
                    <span>{{ tags }}</span>
                  </b-badge>
                </div>
              </div> -->
            </div>

            <div class="list-group list-group-flush">
              <!-- <div class="list-group-item">
                <a :href="item.weblink != '-' ? item.weblink : ''" target="_blank"><feather-icon icon="MousePointerIcon" class="mr-25" /> Web Link</a>
              </div> -->
              <!-- :to="{ name: 'reportofincident', params: { sendinfo: JSON.stringify(item) } }" -->
              <div class="list-group-item">
                <b-link
                  class="col-12 col-sm-6 col-lg-3"
                  @click="
                    () => {
                      $store.dispatch('addStates', item);
                      $router.push({ name: 'mapsingle' });
                    }
                  "
                >
                  <a><feather-icon icon="MapPinIcon" class="mr-25" />GPS</a>
                </b-link>
              </div>
            </div>
            <hr class="p-0 m-0" />
            <div class="row col-12">
              <!-- <div class="col-6 text-center">
                <b-button @click="() => {}" variant="flat-warning" squared block> แก้ไข </b-button>
              </div> -->

              <div class="col-6">
                <b-button @click="deleteItems(item)" variant="flat-danger" squared block> ลบ </b-button>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-between flex-wrap">
              <div class="flex-wrap">
                <small class="text-muted">
                  Create: <cite>{{ formatToThaiTime(item.showDate) }}</cite>
                </small>
              </div>
              <div class="flex-wrap">
                <small class="text-muted">
                  Update: <cite>{{ formatTofromNow(item.registerDate) }}</cite>
                </small>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <b-card v-show="items.length != 0" class="p-0 mb-1" no-body>
      <b-nav class="navbar d-flex justify-content-between bd-highlight flex-wrap">
        <!-- <div class="bd-highlight d-flex align-items-center text-nowrap">Showing 1 to: <b-form-select v-model="perPage" :options="perPageOption" size="sm" @change="handleItemsPerPageChange" /> of {{ totalItems }}</div> -->
        <div>Showing {{ currentPage }} to {{ perPage }} of {{ totalPage }} entries</div>
        <div class="bd-highlight">
          <!-- <div v-show="items.length !== totalPage">
          <b-button @click="showmore()">{{ items.length }}/{{ totalPage }}</b-button>
        </div> -->

          <b-pagination v-model="currentPage" :total-rows="totalPage" :per-page="perPage" @input="handlePageChange" class="mb-0" first-text="First" prev-text="Prev" next-text="Next" align="left" last-text="Last" hide-goto-end-buttons />
        </div>
      </b-nav>
    </b-card>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

  import 'swiper/css/swiper.css';
  import axios from 'axios';
  import API from '@/connect/config';
  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const buddhistEra = require('dayjs/plugin/buddhistEra');

  dayjs.extend(buddhistEra);
  const relativeTime = require('dayjs/plugin/relativeTime');

  dayjs.extend(relativeTime);

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        statusValue: [],
        typeName: 'inform',
        currentPage: 1, // จำนวนรายการที่แสดงในแต่ละหน้า
        perPage: 6, // จำนวนรายการที่แสดงในแต่ละหน้า
        totalPage: 0, // จำนวนรายการ
        // ? loading
        playLoad: true,
        isNodata: false,
        // perPageOption: [
        //   { value: 8, text: 8 },
        //   { value: 10, text: 10 },
        //   { value: 15, text: 15 },
        // ],
        items: [],
        searchItems: '',

        API: '',
        access_token: '',
        swiperOptions: {
          lazy: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
      };
    },

    created() {
      this.getDataTable();
    },
    methods: {
      async deleteItems(value) {
        this.$swal({
          title: 'ลบข้อมูล?',
          text: 'คุณต้องการจะลบข้อมูล ใช่หรือไม่!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ลบ',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            // console.log(value);
            const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
            const { ticketId } = value;
            const data = {
              userId: userId,
              ticketId: ticketId,
            };

            await axios
              .delete(`${API}ticket`, { data: data })
              .then(async (el) => {
                if (el.data.message == 'delete completed') {
                  this.getDataTable();
                  this.$swal({
                    icon: 'success',
                    title: 'ลบข้อมูล!',
                    text: 'ลบข้อมูลสำเร็จ',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });

            return;
          }
        });
      },
      statusChang(value) {
        console.log(value);
        this.statusValue = value;
        this.getDataTable();
      },
      showmore() {
        this.currentPage += 1;
        this.getDataTable();
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.items = [];
        this.getDataTable();
      },
      handleItemsPerPageChange() {
        // กรณีที่ต้องการให้เลือกจำนวนรายการที่แสดงเปลี่ยนแปลงทันที
        this.currentPage = 1;
        this.items = [];
        this.getDataTable();
      },
      formatTofromNow(date) {
        // เวลาที่อัพเดท
        return dayjs().to(dayjs(date));
      },
      formatToThaiTime(date) {
        // เปลี่ยนวันที่ไทย
        return dayjs.tz(date, 'Asia/Bangkok').format('DD-MM-BBBB HH:ss');
      },

      async getDataTable() {
        const { userId, uId } = JSON.parse(localStorage.getItem('company_jwt_vis'));

        this.$store
          .dispatch('getData', `ticket/${userId}?uId=${uId}&type=${this.typeName}&status=${this.statusValue}&_page=${this.currentPage}&_limit=${this.perPage}`)
          .then(async (res) => {
            res.message.result.sort(function (a, b) {
              if (a.registerDate < b.registerDate) return 1;
              else if (a.registerDate > b.registerDate) return -1;
              else return 0;
            });
            console.log(res.message);
            await res.message.result.forEach(async (el) => {
              const image = [];
              this.$store.dispatch('getData', `chat/${userId}?ticketId=${el.ticketId}`).then(async (res) => {
                if (res.message.result.length != 0) {
                  //console.log(res.message.result);
                  res.message.result.forEach(async (res) => {
                    image.push(await this.$store.dispatch('getImageURL', res.chatImage).then((res) => res));
                  });
                } else {
                  image.push('https://th.bing.com/th/id/OIP.MvgjeMBHxcKil9_H4jQcvgAAAA?rs=1&pid=ImgDetMain');
                }
              });
              // console.log(image);
              el.urlImage = image;
            });
            res.message.result.sort(function (a, b) {
              if (a.registerDate < b.registerDate) return 1;
              else if (a.registerDate > b.registerDate) return -1;
              else return 0;
            });
            this.items = res.message.result;
            this.totalPage = res.message.total;
            this.playLoad = false;
            this.isNodata = this.items.length == 0 ? true : false;

            // this.API = this.$store.state.distantAPI.api;
            // this.access_token = this.$store.state.distantAPI.access_token;
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/swiper.scss';
  .item-description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
